import React from "react";
import { PageHeader, Row, Col } from "antd";
import "../styles/Navigation_Styles.css";
import "../styles/Colour_Styles.css";
import Search from "antd/lib/input/Search";
import { useWindowDimensions } from "../logic/Size_Logic";
import { Link, useHistory } from "react-router-dom";

export default function NavigationBar(props) {
  const { width } = useWindowDimensions();
  const history = useHistory();

  // Handle search product event
  const onSearch = (value) => {
    if ( value === "" ) {
      localStorage.removeItem('search');
      localStorage.removeItem('filter');
      if ( !history.location.search.includes('categories') ) {
        history.go(0);
      }
    }
    else {
      localStorage.setItem('search', value);
      if ( !history.location.pathname.includes("Semua-Produk") ) {
        history.push("/Semua-Produk/1/"); // If user is not on all product page then go to that page
      } else {
        history.go(0); // If user is on all product page then refresh to reload new data
      }
    }
  };

  return (
    <PageHeader className="site-page-header">
      <Row justify="space-between" align="middle">
        <Col className="site-page-col-icon">
          <Link to="/">
            {width < 598 ? (
              <img
                src={
                  process.env.PUBLIC_URL + "/Rentatoy-Logo/Untitled-2-03.png"
                }
                alt="Logo"
              />
            ) : (
              <img
                src={
                  process.env.PUBLIC_URL + "/Rentatoy-Logo/Untitled-2-02.png"
                }
                alt="Logo"
              />
            )}
          </Link>
        </Col>
        <Col
          className= "site-page-col-search"
        >
          <Search
            className="site-page-header-search"
            placeholder="Search"
            defaultValue={props.defaultValue}
            allowClear
            onSearch={onSearch}
          />
        </Col>
      </Row>
    </PageHeader>
  );
}
