import React from "react";
import { Card, Skeleton } from "antd";
import { useWindowDimensions } from "../logic/Size_Logic";
import LinesEllipsis from "react-lines-ellipsis";
import NumberFormat from "react-number-format";

export default function CardProdukCom(props) {
  const {width} = useWindowDimensions();
  return (
    <Card
      className={props.clas}
      cover={
        props.loading ? (
          <Skeleton.Image />
        ) : (
          <img alt={props.produk.title} src={props.produk.product_image} />
        )
      }
    >
      <Skeleton loading={props.loading} active paragraph={{ rows: width < 598 ? 1 : ""}}>
        <LinesEllipsis
          style={{ color: "#ce3336" }}
          text={props.produk.title}
          maxLine="1"
          ellipsis="..."
          basedOn="letters"
        />
        <span>Sewa</span>
        <p>
          {`Harga `}
          <span>
            <NumberFormat
              value={props.produk.price}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              prefix={"Rp. "}
              suffix=",-"
            />
          </span>
        </p>
      </Skeleton>
    </Card>
  );
}
