import React from "react";
import { Checkbox, Skeleton, Button } from "antd";

const CustomCheckbox = (props) => {
  return (
    <li>
      <Checkbox
        id={props.id}
        checked={props.isChecked}
        onChange={props.handelChange}
        value={props.value}
      >
        {props.value}
      </Checkbox>
    </li>
  );
};

export default function FilterCom(props) {
  return (
    <>
      <ul className="checkbox-list">
        <span>Tipe Barang</span>
        <br />
        <Skeleton loading={props.loading} active>
          {props.bestseller.map((value) => {
            return (
              <CustomCheckbox
                handelChange={props.handleChildElement}
                {...value}
                key={value.id}
                id={value.id}
              />
            );
          })}
        </Skeleton>
        <Skeleton loading={props.loading} active>
          <Checkbox
            onChange={props.handlePeralatan}
            value={"Peralatan"}
            checked={props.checkedPeralatan}
          >
            Peralatan
          </Checkbox>
          <ul>
            {props.peralatan.map((value) => {
              return (
                <CustomCheckbox
                  handelChange={props.handleChildElement}
                  {...value}
                  key={value.id}
                  id={value.id}
                />
              );
            })}
          </ul>
        </Skeleton>
        <Skeleton loading={props.loading} active>
          <Checkbox
            onChange={props.handlePermainan}
            value={"Permainan"}
            checked={props.checkedPermainan}
          >
            Permainan
          </Checkbox>
          <ul>
            {props.permainan.map((value) => {
              return (
                <CustomCheckbox
                  {...value}
                  handelChange={props.handleChildElement}
                  key={value.id}
                  id={value.id}
                />
              );
            })}
          </ul>
        </Skeleton>
        <br />
        <Skeleton loading={props.loading} active>
          <span>Usia</span>
          {props.usia.map((value) => {
            return (
              <CustomCheckbox
                handelChange={props.handleChildElement}
                {...value}
                key={value.id}
                id={value.id}
              />
            );
          })}
        </Skeleton>
        <br />
        <Skeleton loading={props.loading} active>
          <span>Ketersediaan Barang</span>
          {props.barang.map((value) => {
            return (
              <CustomCheckbox
                handelChange={props.handleChildElement}
                {...value}
                key={value.id}
                id={value.id}
              />
            );
          })}
        </Skeleton>
        <br />
        <Skeleton loading={props.loading} active>
          <span>Outdoor dan Indoor</span>
          {props.lain.map((value) => {
            return (
              <CustomCheckbox
                handelChange={props.handleChildElement}
                {...value}
                key={value.id}
                id={value.id}
              />
            );
          })}
        </Skeleton>
        {/* <Button className="apply-btn" onClick={props.onClickApply}>APPLY</Button> */}
      </ul>
    </>
  );
}
