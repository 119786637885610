import Axios from "axios";
const BASE_URL = "https://api.rental-mainan.com/";

/**
 * Translate error code to message
 * @param error: error code (ex: 404)
 */
 const handleError = error => {
    let errorResponse = null;
    if (error.code === "ECONNABORTED") {
        errorResponse = {
            status: 408,
            error: [{ msg: error.message }],
            message: "Request Time Out",
        };
    }
    else if (error.response) {
        errorResponse = error.response.data;
    }
    else {
        errorResponse = {
            status: 501,
            error: [{ msg: "Server Implementation Error" }],
        };
    }
    return errorResponse;
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get product categories
 */
 export const getCategories = async () => {
    var errorResponse = null;
    var tokenData;
    await Axios({
        method: "get",
        url: "category",
        baseURL: BASE_URL.concat("api/v1/"),
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
    })
    .then(function(response) {
        tokenData = response.data;
    })
    .catch(function(error) {
        errorResponse = handleError(error);
    });
    return [tokenData, errorResponse];
}

/**
 * Get products data based on selected categories
 */
 export const getProductsByCategories = async (url) => {
    var errorResponse = null;
    var tokenData;

    await Axios({
        method: "get",
        url: "product/client/all" + url,
        baseURL: BASE_URL.concat("api/v1/"),
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
    })
    .then(function(response) {
        tokenData = response.data;
    })
    .catch(function(error) {
        errorResponse = handleError(error);
    });
    return [tokenData, errorResponse];
}

/**
 * Get specific product
 * @param id: product's ID
 */
 export const getProduct = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "product/" + id,
    baseURL: BASE_URL.concat("api/v1/"),
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Create new customer
 * @param data: data required to create new customer 
 */
 export const postCustomer = async data => {
    var errorResponse = null;
    var tokenData;
    var status;
    let form = new FormData();
    const rt = data.rt.length === 4? ("0" + data.rt.substring(3)) : data.rt.substring(3);
    const rw = data.rw.length === 4? ("0" + data.rw.substring(3)) : data.rw.substring(3);
  
    if ( data.ktp ) form.append("id_card", data.ktp.fileList[0].originFileObj);
    if ( data.kk ) form.append("family_card", data.kk.fileList[0].originFileObj);
    if (data.penanggung_jawab) form.append("name_person", data.penanggung_jawab);
    form.append("phone_person", data.no_hp_penanggung_jawab);
    form.append("name_couple", data.name);
    form.append("phone_couple", data.no_hp);
    form.append("address", data.address);
    form.append("details", data.keterangan);
    form.append("rt_rw", (rt + "/" + rw));
    form.append("kelurahan", data.lurah);
    form.append("kecamatan", data.camat);
    form.append("phone_home", data.no_rumah);
    form.append("term_and_condition", true);
  
    await Axios({
      method: "post",
      url: "customer/register",
      baseURL: BASE_URL.concat("api/v1/"),
      data: form,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    })
      .then(function(response) {
        tokenData = response.data;
        status = response.status;
      })
      .catch(function(error) {
        errorResponse = handleError(error);
        status = error;
      });
    return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get homepage data
 */
 export const getHomepage = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: BASE_URL.concat("home"),
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}