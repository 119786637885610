import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Divider,
  Row,
  Col,
  Upload,
  message,
  Modal,
  Checkbox,
} from "antd";
import SyaratKetentuan from "../components/SyaratKetentuan_Com";
import "../styles/Default_Styles.css";
import "../styles/Pendaftaran_Styles.css";
import "../styles/Colour_Styles.css";
import PhoneInput from 'react-phone-number-input'
import { Link } from "react-router-dom";
import { postCustomer } from '../logic/APIHandler';

export function FormCom() {

  const formItemLayout = {
    labelCol: {
      sm: { span: 24 },
      md: { span: 12 },
      lg: { span: 9 },
      xl: { span: 7 },
      style: {
        textAlign: "left",
      },
    },
    wrapperCol: {
      sm: { span: 24 },
      md: { span: 14 },
      lg: { span: 16 },
      xl: { span: 16 },
      style: {
        marginBottom: "20px",
      },
    },
  };
 
  const [form] = Form.useForm();
  const history = useHistory();  
  const [errorText, setErrorText] = useState();
  const [state, setState] = useState({
    isLoading: false,
    submittedData: [],
    showModal: false,
    showModal2: false,
    showModal3: false,
    error:false,
  });

  // Menampilkan modal yang berisi syarat & ketentuan
  const triggerModal = (data) => {
    setState({
      ...state,
      submittedData: data,
      showModal: true,
    });
  };

  // Menampilkan modal yang berisi syarat & ketentuan
  const triggerModal2 = async data => {
    setState({ ...state, isLoading: true, });
    
    // Register new customer
    let resp = await postCustomer(state.submittedData);
    // Jika berhasil, maka tampilkan modal berhasil
    if ( resp[0] && resp[0].status === 200 ) {
      setTimeout(() => {
        setState({
          ...state,
          showModal: false,
          isLoading: false,
        });
      }, 2000);
      setTimeout(() =>{
        setState({ showModal2: true, });
      }, 3500);
    }
    // Jika gagal, tampilkan modal pendaftaran gagal
    else {
      // Jika error dari server/API
      if ( resp[1] && resp[1].message ) {
        setErrorText(resp[1].message);
        setTimeout(() => {
          setState({
            ...state,
            showModal: false,
            isLoading: false,
          });
        }, 2000);
      }
      // Jika error dari jaringan internet / browser
      else {
        setErrorText("Pastikan internet Anda stabil dan browser Anda terupdate.");
        setTimeout(() => {
          setState({
            ...state,
            errorText: resp[1].message,
            showModal: false,
            isLoading: false,
          });
        }, 2000);
      }
      setTimeout(() =>{
        setState({ showModal3: true, });
      }, 3500);
    }
    
  };

  // Menutup semua modal
  const triggerCancel = () => {
    setState({
      showModal: false,
      showModal2: false,
      showModal3: false,
    });
  };

  // Handle onClick button on modal
  const onSubmit = () => {
    setState({
      ...state,
      showModal2: false,
    });
  };
  
  // Image uploader props
  const props = {
    name: "file",
    maxCount: 1,
    beforeUpload: file => {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        message.error(`format file harus png atau jpg`);
        return Upload.LIST_IGNORE;
      }
      else{
        message.success(`${file.name} berhasil di upload`);
      }
      return false;
    },
  };

  return (
    <>
      <Modal
        title="Apakah Anda Setuju?"
        centered
        visible={state.showModal}
        onOk={onSubmit}
        confirmLoading={state.isLoading}
        onCancel={triggerCancel}
        footer={false}
      >
        <Row>
          <h3 style={{ color: "#F16663" }}>Syarat dan Ketentuan Berlaku</h3>
        </Row>
        <Row>
          <div className="box-syarat-ketentuan">
            <h3>Cara dan Ketentuan Menyewa</h3>
            <SyaratKetentuan />
            <h3>
              Dengan ini Pihak Penyewa telah mengetahui dan menyetujui ketentuan
              sewa yang berlaku. Terimakasih.
            </h3>
          </div>
        </Row>
        <Row>
          <Form onFinish={triggerModal2}>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[{
                validator: (_, value) =>
                  value? Promise.resolve()
                    : Promise.reject(new Error(
                          "Mohon menyetujui syarat dan ketentuan berlaku"
                      )),
              },]}
            >
              <Checkbox>Saya Setuju</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                key="submit"
                className="btn-modal"
                htmlType="submit"
                type={"primary"}
                loading={state.isLoading}
                style={{ width: 150 }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Modal>

      {/* Modal jika pendaftaran berhasil (status: 200) */}
      <Modal
        title="Terima Kasih!"
        centered
        visible={state.showModal2}
        onOk={onSubmit}
        confirmLoading={state.isLoading}
        onCancel={triggerCancel}
        footer={[
          <Button type="primary">
            <Link to="/">Kembali Ke Beranda</Link>
          </Button>
        ]}
      >
        <p style={{
          color:"#623310"
        }}>
          Pendaftaran Anda telah diterima. Terima kasih telah mendaftar di
          Rentatoy. Hubungi Kami untuk keterangan lebih lanjut.
        </p>
      </Modal>

      {/* Modal jika pendaftaran gagal */}
      <Modal
        title="Gagal Mendaftar!"
        centered
        visible={state.showModal3}
        // onOk={onSubmit}
        confirmLoading={state.isLoading}
        onCancel={triggerCancel}
        footer={[
          <Button type="primary" onClick={() => history.go(0)}>
            Reload
          </Button>
        ]}
      >
        <p style={{
          color:"#623310"
        }}>
          {errorText}
        </p>
      </Modal>

      <Form
        {...formItemLayout}
        form={form}
        action="/"
        onFinish={triggerModal}
        scrollToFirstError
        requiredMark="optional"
        colon={false}
      >
        <Form.Item
          name="penanggung_jawab"
          label="Nama Penanggung Jawab"
          rules={[
            {
              required: true,
              message: "Mohon masukan nama Penanggung Jawab",
            },
          ]}
        >
          <Input placeholder="Budi Utomo" />
        </Form.Item>
        <Form.Item
          name="no_hp_penanggung_jawab"
          label="Nomor Handphone Penanggung Jawab"
          rules={[
            {
              required:true,
              validator: (_, value) =>
                value
                  ? Promise.resolve(
                    setState({
                      ...state,
                      error: false
                    })
                  )
                  : Promise.reject(
                      new Error(
                        "Mohon masukan nomor handphone Penanggung Jawab"
                      ),
                      setState({
                        ...state,
                        error: true
                      })
                    ),
            },
          ]}
        >
          <PhoneInput
            className={state.error ? "error" : ""}
            defaultCountry="ID"
            placeholder="+62 870 000 000"
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Nama Lengkap Suami / Istri"
          rules={[
            {
              required: true,
              message: "Mohon masukan nama Suami atau Istri Anda",
            },
          ]}
        >
          <Input placeholder="Rentatoy" />
        </Form.Item>

        <Form.Item
          name="no_hp"
          label="Nomor Handphone Suami / Istri"
          rules={[
            {
              required:true,
              validator: (_, value) =>
                value
                  ? Promise.resolve(
                    setState({
                      ...state,
                      error: false
                    })
                  )
                  : Promise.reject(
                      new Error(
                        "Mohon masukan nomor handphone Suami atau Istri Anda"
                      ),
                      setState({
                        ...state,
                        error: true
                      })
                    ),
            },
          ]}
        >
          <PhoneInput
            className={state.error ? "error" : ""}
            defaultCountry="ID"
            placeholder="+62 870 000 000"
          />
        </Form.Item>
        <Divider />
        <p
          style={{
            color: "#623310",
            fontWeight: "bold",
            paddingBottom: "20px",
          }}
        >
          Alamat Tinggal Lengkap
        </p>
        <div className="address-form">
          <Form.Item
            name="address"
            label="Alamat"
            rules={[
              {
                required: true,
                message: "Mohon masukan Alamat Anda",
              },
            ]}
          >
            <Input placeholder="Jl.Contoh" />
          </Form.Item>
          <Form.Item
            name="keterangan"
            label="Keterangan"
          >
            <Input.TextArea placeholder="Pagar warna merah" />
          </Form.Item>
          <Form.Item
            label="RT/RW"
            name="rt/rw"
            rules={[
              {
                required:true,
                validator: (_, value) =>
                  value
                    ? Promise.resolve(
                      setState({
                        ...state,
                        error: false
                      })
                    )
                    : Promise.reject(
                        new Error(
                          "Mohon masukan nomor RT dan RW Alamat Anda"
                        ),
                        setState({
                          ...state,
                          error: true
                        })
                      ),
              },
            ]}
          >
            <Row gutter={8}>
              <Col xs={8} lg={6} xl={3}>
                <Form.Item name="rt">
                  <PhoneInput
                    className={state.error ? "rtrw error" : "rtrw"}
                    defaultCountry="ID"
                    placeholder="01"
                    maxLength={2}
                  />
                </Form.Item>
              </Col>
              <Col xs={8} lg={6} xl={3}>
                <Form.Item name="rw">
                  <PhoneInput
                    className={state.error ? "rtrw error" : "rtrw"}
                    defaultCountry="ID"
                    placeholder="01"
                    maxLength={2}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="lurah"
            label="Kelurahan"
            rules={[
              {
                required: true,
                message: "Mohon masukan Alamat Kelurahan Anda",
              },
            ]}
          >
            <Input placeholder="Rentatoy" />
          </Form.Item>
          <Form.Item
            name="camat"
            label="Kecamatan"
            rules={[
              {
                required: true,
                message: "Mohon masukan Alamat Kecamatan Anda",
              },
            ]}
          >
            <Input placeholder="Rentatoy" />
          </Form.Item>
        </div>
        <Divider />
        <Form.Item
          name="no_rumah"
          label="Nomor Telefon Rumah"
          rules={[
            {
              required:true,
              validator: (_, value) =>
                value
                  ? Promise.resolve(
                    setState({
                      ...state,
                      error: false
                    })
                  )
                  : Promise.reject(
                      new Error(
                        "Mohon masukan nomor telefon rumah Anda"
                      ),
                      setState({
                        ...state,
                        error: true
                      })
                    ),
            },
          ]}
        >
          <PhoneInput
            className={state.error ? "error" : ""}
            defaultCountry="ID"
            placeholder="022-00000000"
          />
        </Form.Item>
        <Form.Item
          name="ktp"
          valuePropName="file"
          label="Foto KTP Penanggung Jawab"
          rules={[
            {
              required: true,
              message: "Mohon upload foto KTP Anda",
            },
          ]}
        >
          {/* "beforeUpload() = false" di bawah untuk menghilangkan animasi upload image */}
          <Upload {...props}>
            <Button type="primary" style={{width:150, fontSize: "12px"}}>Unggah</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="kk"
          valuePropName="file"
          label="Foto KK Penanggung Jawab"
          rules={[
            {
              required: true,
              message: "Mohon upload foto KK Anda",
            },
          ]}
          style={{marginBottom:0}}
        >
          {/* "beforeUpload() = false" di bawah untuk menghilangkan animasi upload image */}
          <Upload {...props}>
            <Button type={"primary"} style={{width:150, fontSize: "12px"}}>Unggah</Button>
          </Upload>
        </Form.Item>

        <p style={{ color: "#F16663", fontWeight: "bold" }} id="tooltips">
          * Tipe file yang diunggah hanya boleh jpg/png (maks. 5MB)
        </p>
        
        <Form.Item className="btn-daftar">
          <Button
            className="btn-form"
            type="primary"
            htmlType="submit"
            // onClick={cekNomor}
          >
            DAFTAR
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
