export const dataKategori = [
    {
        id: 0,
        name : "Peralatan",
        image : "Rentatoy-Aset/Baby-Trolley.svg",
        className : "baby-trolley",
        path: "Semua-Produk?categories=cat2&categories=pr0&categories=pr1&categories=pr2&categories=pr3&categories=pr4&categories=pr5&categories=pr6&categories=pr7&",
    },
    {
        id: 1,
        name : "Permainan",
        image : "Rentatoy-Aset/Toy-Box.svg",
        className : "Toy-Box",
        path: "Semua-Produk?categories=cat3&categories=pm0&categories=pm1&categories=pm2&categories=pm3&categories=pm4&categories=pm5&categories=pm6&categories=pm7&categories=pm8&",
    },
    {
        id: 2,
        name : "Usia",
        image : "Rentatoy-Aset/Toy-Age.svg",
        className : "Toy-Age",
        path: "Semua-Produk?categories=us0&categories=us1&categories=us2&categories=us3&categories=us4&categories=us5&categories=us6&categories=us7&categories=us8&",
    },
    {
        id: 3,
        name : "Disewa",
        image : "Rentatoy-Aset/Toy-Rent.svg",
        className : "Toy-Rent",
        path: "Semua-Produk?categories=kb2&",
    },
    {
        id: 4,
        name : "Available",
        image : "Rentatoy-Aset/Toy-Available.svg",
        className : "Toy-Available",
        path: "Semua-Produk?categories=kb1&",
    },
    {
        id: 5,
        name : "New Item",
        image : "Rentatoy-Aset/Toy-Sun.svg",
        className : "Toy-Sun",
        path: "Semua-Produk?categories=kb0&",
    },
    {
        id: 6,
        name : "Outdoor",
        image : "Rentatoy-Aset/Toy-Outdoor.svg",
        className : "Toy-Outdoor",
        path: "Semua-Produk?categories=oi0&",
    },
    {
        id: 7,
        name : "Indoor",
        image : "Rentatoy-Aset/Toy-Indoor.svg",
        className : "Toy-Indoor",
        path: "Semua-Produk?categories=oi1&",
    },
    
]