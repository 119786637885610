import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Skeleton, Spin } from "antd";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Produk from "../components/ProdukBaru_Com";
import Footer from "../components/Footer_Com";
import Nav from "../components/NavigationBar_Com";
import { getProduct } from "../logic/APIHandler";
import { useWindowDimensions } from "../logic/Size_Logic";

export default function DetailProduk(props) {
  const defaultSearch = localStorage.getItem("search")? localStorage.getItem("search") : "";
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  const [state, setState] = useState({
    title: null,
    keterangan: null,
    produk: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const idProduct = props.match.params.id;
    if (idProduct) {
      getData(idProduct);
    } else {
      console.log("ID not found");
    }
  }, [props.match.params.id]);

  const getData = async (id) => {
    setIsLoading(true);
    let resp = await getProduct(id);
    if (resp[0] && resp[0].status === 200) {
      const product = resp[0].data;
      let productSliders = [];
      productSliders.push({
        product_image: product.product_image,
      });
      product.product_slider.forEach((slider) => {
        if (slider) {
          productSliders.push({
            product_image: slider,
          });
        }
      });
      setState({
        ...state,
        title: product.title,
        keterangan: product.description,
        produk: productSliders,
      });
    } else {
      console.log("Couldn't get product's data");
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <div className="container">
      <Nav defaultValue={defaultSearch} />
      <div className="beranda">
        {width > 992 ? (
          <div className="breadcrumb">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Beranda</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/Semua-Produk/1/" onClick={() => localStorage.removeItem("search")}>Semua Produk</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item id="current">{state.title}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        ) : (
          ""
        )}
        <div className="site-page-detail-produk">
          <div className="slider-detail-produk">
            <Spin spinning={isLoading}>
              <Produk
                data={state.produk}
                slidesToShow={1}
                className="detail card-produk"
                pageType="detailed"
                loading={isLoading}
                initialSlide={0}
                centerPadding=""
                centerMode= {false}
                arrows ={true}
                clasessName="card-produk-detail"
              />
            </Spin>
          </div>
          <div className="keterangan-detail-produk">
            <Skeleton loading={isLoading} active>
              <h2>{state.title}</h2>
              <p>Sewa</p>
              <p>Khusus Daerah Bandung</p>
              {/* <h4>Keterangan</h4> */}
              {/* <ul style={{ listStyle: "dash" }}>
                <li>{ReactHtmlParser(state.keterangan)}</li>
              </ul> */}
              <p style={{ color: "initial" }} className="detail-produk">{ReactHtmlParser(state.keterangan)}</p>
              <div className="btn-detail-produk">
                <Link to="/Cara-Ketentuan">
                  <Button type="primary">Cara dan Ketentuan Menyewa</Button>
                </Link>
              </div>
            </Skeleton>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
