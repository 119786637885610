import '../src/App.less';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home_Page";
import Kategori from "./pages/Kategori_Page";
import Produk from "./pages/Produk_Page";
import CaraKetentuan from "./pages/CaraKetentuan_Page";
import TentangKami from "./pages/TentangKami_Page";
import Pendaftaran from "./pages/Pendaftara_Page";
import DetailProduk from "./pages/DetailProduk_Page";
import 'antd/dist/antd.less';

function App() {
  return (
    <>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Semua-Kategori" component={Kategori}/>
        <Route exact path="/Semua-Produk/:page?/:id?" component={Produk}/>
        <Route exact path="/Cara-Ketentuan" component={CaraKetentuan}/>
        <Route exact path="/Tentang-Kami" component={TentangKami}/>
        <Route exact path="/Pendaftaran" component={Pendaftaran}/>
        <Route exact path="/Detail-Produk/:id?" component={DetailProduk}/>
      </Switch>
    </Router>
    </>
  );
}

export default App;
