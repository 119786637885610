import React from "react";
import LinesEllipsis from 'react-lines-ellipsis';
import { List, Card, Skeleton,  } from "antd";
import {useWindowDimensions} from "../logic/Size_Logic";
import NumberFormat from 'react-number-format';
import "../styles/Produk_Styles.css"
import { Link, useHistory } from "react-router-dom";

export default function AllProdukCom(props) {

  const {width} = useWindowDimensions(); 
  const history = useHistory();

  return (
    <>
      <List
        key="all-produk"
        grid={{
          gutter: [16, width > 992 ? 48 : 24],
          column:  width > 598 ? 3 : 2,
        }}
        itemLayout="vertical"
        loading={props.loading}
        pagination={{
          onChange: (page) => {
            history.replace({
              pathname: `/Semua-Produk/${page}/`
            })
            window.scrollTo(0, 0);
          },
          defaultCurrent: parseInt(props.currPage),
          pageSize: width > 992 ? 18 : 9 && width > 598 ? 9 : 8,
          simple: true,
          style: {display: (props.loading || props.dataSource.length === 0) ? "none" : ""} 
        }}
        dataSource={props.dataSource}
        renderItem={(item) => (
          <Link to={`/Detail-Produk/${item._id}`} key={`all-produk-${item.id}`}>
            <List.Item>
              <Card
                style={{height : props.loading ? "100%" : ""}}
                className="card-produk all"
                cover={
                  props.loading ? (
                    <Skeleton.Image/>
                  ) : (
                    <img alt={item.title} src={item.product_image} />
                  )
                }
              >
                <Skeleton loading={props.loading} active paragraph= {{rows: width < 992 ? 2 : 3}}>
                  <LinesEllipsis
                    style={{color:"#ce3336"}}
                    text={item.title}
                    maxLine='1'
                    ellipsis="..."
                    basedOn="letters"
                  />
                  <span>Sewa</span>
                  <p>{`Harga `}
                    <span className="number-format">
                      <NumberFormat
                        value={item.price}
                        displayType={'text'}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix={'Rp. '}
                        suffix=",-" />
                    </span>
                  </p>
                </Skeleton>
              </Card>
            </List.Item>
          </Link>
        )}
      ></List>
    </>
  );
}