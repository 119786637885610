import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import CardProduk from "../components/CardProduk_Com";
import { useWindowDimensions } from "../logic/Size_Logic";
import "../styles/Produk_Styles.css";

export default function ProdukBaruCom(props) {
  const { width } = useWindowDimensions();
  const generateCarousel = (data, slide, respon, className, loading) => {
    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: slide,
      swipeToSlide: true,
      initialSlide: 0,
      centerPadding : props.centerPadding,
      initialSlide : props.initialSlide,
      className: props.clasessName,
      centerMode: props.centerMode,
      arrows : props.arrows,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: respon,
    };
    var cards = [];
    if (props.pageType === "detailed") {
      for (var i = 0; i < data.length; i = i + 1) {
        cards.push(
          <CardProduk
            clas={className}
            loading={loading}
            produk={data[i]}
            key={`detail-product-${i}`}
          />
        );
      }
    } else {
      for (var j = 0; j < data.length; j = j + 1) {
        cards.push(
          <Link
            to={`Detail-Produk/${data[j]._id}`}
            key={`newest-product-${data[j].id}`}
          >
            <CardProduk clas={className} loading={loading} produk={data[j]} />
          </Link>
        );
      }
    }
    let carousel = <Slider {...settings}>{cards}</Slider>;
    return carousel;
  };
  return (
    <>
      {generateCarousel(
        props.data,
        props.slidesToShow,
        props.responsive,
        props.className,
        props.loading
      )}
    </>
  );
}
function SamplePrevArrow(props) {
  const { onClick, className } = props;
  return (
    <img
      src={process.env.PUBLIC_URL + "/Rentatoy-Aset/Previcon-Aset.svg"}
      alt="icon-prev"
      className={className + " icon-prev-produk"}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { onClick, className } = props;
  return (
    <img
      src={process.env.PUBLIC_URL + "/Rentatoy-Aset/Nexticon-Aset.svg"}
      alt="icon-next"
      className={className + " icon-next-produk"}
      onClick={onClick}
    />
  );
}
