import React, { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import { Link } from "react-router-dom";
import Nav from "../components/NavigationBar_Com";
import Carousel from "../components/Carousel_Com";
import Kategori from "../components/Kategori_Com";
import Footer from "../components/Footer_Com";
import Produk from "../components/ProdukBaru_Com";
import { dataKategori } from "../data/DataKategori_Data";
import { getProductsByCategories } from "../logic/APIHandler";
import { getHomepage } from "../logic/APIHandler";
import { useWindowDimensions } from "../logic/Size_Logic";
import "../styles/Kategori_Styles.css";
import "../styles/Produk_Styles.css";
import "../styles/Default_Styles.css";
import "../styles/Colour_Styles.css";
import "../styles/Beranda_Styles.css";

export default function Home() {
  const [banner, setBanner] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("search");
    localStorage.removeItem("filter");
    getBanner();
    getProducts();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const getBanner = async () => {
    let homepage = await getHomepage();
    let result = [{ id: 0, image: "Rentatoy-Aset/Mom-and-Son.jpg" }];
    if ( homepage[0] && homepage[0].data.config != null ) {
      if ( homepage[0].data.config.company_banner != null ) {
        const banners = homepage[0].data.config.company_banner;
        for (let i = 0; i < banners.length; i++) {
          if (banners[i]) {
            result.push({
              id: i + 1,
              image: banners[i],
            });
          }
        }
      }
    }
    setBanner(result);
  };

  const getProducts = async () => {
    if (!loading) {
      setLoading(true);
      let products = await getProductsByCategories("");
      let result = [];
      if ( products[0] && products[0].status !== 204 ) {
        let i = 0;
        products[0].data.forEach((product) => {
          if (i < 10) {
            result.push(product);
            i++;
          }
        });
      }
      setProducts(result);
    }
  };

  return (
    <div className="container home">
      <Nav />
      <Spin spinning={loading}>
        <Carousel data={banner} />
      </Spin>
      {width > 992 ? (
        <div className="beranda">
          <div className="site-page-kategori">
            <Row>
              <h1 className="sub-title">
                <strong>KATEGORI</strong>
              </h1>
            </Row>
            <Kategori data={dataKategori} />
            <Row>
              <Col
                xs={{ span: 7, offset: 20 }}
                sm={{ span: 8, offset: 18 }}
                md={{ offset: 19 }}
                lg={{ offset: 20 }}
                className="col-all-kategori"
              >
                <Link to="/Semua-Kategori">
                  <p>semua kategori &gt;&gt;</p>
                </Link>
              </Col>
            </Row>
          </div>
          <div className="site-page-produk">
            <Row>
              <h1 className="sub-title">
                <strong>PRODUK TERBARU</strong>
                <Link to="/Semua-Produk/1/">Lihat semua</Link>
              </h1>
            </Row>
            <Spin spinning={loading}>
              <Produk
                loading={loading}
                className={width > 1100 ? "card-produk" : "card-produk landscape"}
                data={products}
                initialSlide={0}
                slidesToShow={width > 1100 ? 4 : 3 && width > 598 ? 3 : 2}
                centerMode= { false }
                arrows ={ true }
              />
            </Spin>
          </div>
        </div>
      ) : (
        <>
          <div className="beranda">
            <div className="site-page-kategori">
              <Row>
                <h1 className="sub-title">
                  <strong>KATEGORI</strong>
                </h1>
              </Row>
              <Kategori data={dataKategori} />
              <Row>
                <Col
                  xs={{ span: 7, offset: 20 }}
                  sm={{ span: 8, offset: 18 }}
                  md={{ offset: 19 }}
                  lg={{ offset: 20 }}
                  className="col-all-kategori"
                >
                  <Link to="/Semua-Kategori">
                    <p>semua kategori &gt;&gt;</p>
                  </Link>
                </Col>
              </Row>
              <Row>
                <h1 className="sub-title">
                  <strong>PRODUK TERBARU</strong>
                  <Link to="/Semua-Produk/1/">Lihat semua</Link>
                </h1>
              </Row>
            </div>
          </div>
          <div className="site-page-produk">
            <Spin spinning={loading}>
              <Produk
                loading={loading}
                centerPadding={"10%"}
                centerMode= {true}
                arrows ={false}
                initialSlide={0}
                clasessName={"card-produk-baru"}
                className="card-produk home"
                data={products}
                slidesToShow={width > 992 ? 4 : 3 && width > 598 ? 3 : 2}
              />
            </Spin>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}