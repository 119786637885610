import React, { Component } from "react";
import "../styles/Carousel_Styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default class CarouselCom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeData: { id:0, image: null },
    };
  }
  render() {
    return (
      <div id="home" className="site-page-carousel">
        {this.generateCarousel()}
      </div>
    );
  }
  generateCarousel() {
    const settings = {
      dots: true,
      customPaging: i => (
        <div
          className="slick-custom-dots"
        >
        </div>
      ),
      draggable: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      lazyload: true,
      autoplaySpeed: 5000,
      pauseOnHover:true,
      pauseOnFocus: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    var cards = [];
    if (this.props.data) {
      this.props.data.forEach((data, index) => {
        cards.push(
          <div key={index} className="site-page-carousel-inner">
            <img className="img-banner" src={data.image} alt="gambar" />
          </div>
        );
      });
      let carousel = <Slider {...settings}>{cards}</Slider>;
      return carousel;
    }
  }
}
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <img
      src={process.env.PUBLIC_URL + '/Rentatoy-Aset/Previcon-Aset.svg'}
      alt="icon-prev"
      className="icon-prev"
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <img
      src={process.env.PUBLIC_URL + '/Rentatoy-Aset/Nexticon-Aset.svg'}
      alt="icon-next"
      className="icon-next"
      onClick={onClick}
    />
  );
}
