export const caraKetentuan = [
    {
        id:0,
        text: "Pihak penyewa membayar biaya sewa dan antar-ambil DIMUKA (cash/transfer) sesuai waktu sewa yang disepakati."
    },
    {
        id:1,
        text: "Apabila masa sewa belum jatuh tempo namun pihak penyewa telah mengembalikan barang sewaan maka selisih biaya sewa tidak dapat dikembalikan."
    },
    {
        id:2,
        text: "Barang yang sudah disewa tidak dapat ditukar dengan barang lain dengan alasan apapun sebelum masa sewa habis."
    },
    {
        id:3,
        text:"Untuk mengganti waktu sewa, diberikan waktu 1×24 jam untuk mengubah waktu sewa, misal 2 minggu menjadi 4 minggu. Setelah lewat waktu tersebut, maka akan diberlakukan harga sewa 2 minggu + perpanjang 2 minggu. Untuk penggantian waktu sewa 4 minggu menjadi 2 minggu, uang kelebihan sewa akan kami refund."
    },
    {
        id:4,
        text:"Untuk pemesanan mainan yang diambil ke lokasi/kantor, diberikan waktu 2x 24 jam (2 hari) untuk keep barang, setelah lewat dari 2 hari akan dianggap membatalkan sewa dan akan kami alihkan tanpa perlu menginfokan kepada pemesan sebelumnya."
    },
    {
        id:5,
        text:"Biaya perpanjangan sewa selama 1 minggu dihitung dari biaya sewa 2 minggu dibagi 2."
    },
    {
        id:6,
        text: "Pada saat penyerahan barang sewa, penyewa terlebih dahulu memeriksa kondisi barang, mempelajari fungsi serta pengaturannya. Jika barang yang disewa tidak sesuai dan membatalkan penyewaan, penyewa dikenakan biaya administrasi sebesar IDR 25.000,- plus sewa harian (biaya sewa 2 minggu dibagi 14 hari = sewa harian)"
    },
    {
        id:7,
        text: "Jika mainan yang ada terima rusak/cacat, segera laporkan pada pihak Rentatoy dalam jangka 1x24jam, jika tidak segala kerusakan yang timbul menjadi tanggung jawab penyewa."
    },
    {
        id:8,
        text:"Pihak penyewa telah mengetahui dan melihat barang yang akan disewa dan bersedia mengembalikan barang yang disewa sesuai dengan kondisi pada saat sewa."
    },
    {
        id:9,
        text:"Hak dan kepemilikan barang yang disewa merupakan milik Rentatoy."
    },
    {
        id:10,
        text:"Adapun ketentuan sanksi jika barang mengalami kerusakan:",
    },
    {
        id:11,
        text:"Rentatoy akan menghubungi 1 hari sebelum masa sewa berakhir perihal pengembalian atau perpanjangan masa sewa. Bila tidak memberikan jawaban atau tidak dapat dihubungi, dianggap memperpanjang masa sewa selama 1 minggu."
    },
    {
        id:12,
        text:"Biaya Pengiriman & Pengambilan mainan:"
    },
    {
        id:13,
        text:"Apabila penyewa ingin memperpanjang masa sewa, maka penyewa membayar biaya perpanjang sewa sebelum masa sewa berakhir melalui bank transfer bank."
    },
    {
        id:14,
        text:"Jika terjadi keterlambatan pengembalian barang dan pembayaran perpanjangan sewa, dikenakan biaya keterlambatan per hari sebesar harga 2 minggu sewa dibagi 14 hari (untuk max 2 hari keterlambatan). Lebih dari 2 hari akan dihitung biaya sewa perpanjangan satu minggu."
    },
    {
        id:15,
        text: "Pihak penyewa tidak diperbolehkan meminjamkan atau mengalihkan hak sewa kepada pihak ketiga dengan alasan apapun tanpa persetujuan dari pihak Rentatoy. Kerusakan yang diakibatkan pengalihan barang menjadi tanggungjawab menyewa."
    },
    {
        id:16,
        text:"Pihak penyewa tidak diperbolehkan memindahkan alamat lokasi barang yang disewa tanpa persetujuan dari Rentatoy."
    },
    {
        id:17,
        text:"Pihak penyewa tidak boleh menggunakan barang yang disewa untuk tujuan komersil atau untuk acara tertentu misal ulang tahun, restaurant, hotel, pameran, dll tanpa pemberitahuan kepada pihak Rentatoy."
    },
    {
        id:18,
        text:"Pihak Rentatoy berhak menarik barang yang disewa bila dicurigai atau telah terjadi pelanggaran tanpa persetujuan dari pihak penyewa."
    },
    {
        id:19,
        text:"Pihak Rentatoy tidak bertanggung jawab atas kerusakan dan kecelakaan yang terjadi selama barang disewa atau digunakan oleh pihak penyewa."
    },
    {
        id:20,
        text:"Perjanjian sewa ini berlaku untuk barang yang disewa oleh penyewa dan berlaku baik masa sekarang maupun yang akan datang."
    },
    {
        id:21,
        text:"Pihak Rentatoy berhak untuk merubah dan menambah isi dari perjanjian sewa ini untuk penyesuaian kondisi tanpa pemberitahuan terlebih dahulu."
    },
    {
        id:22,
        text:"Informasi pribadi pihak penyewa yang diberikan pada pihak Rentatoy tidak akan diberikan kepada pihak ketiga tanpa persetujuan dari pihak penyewa."
    }
]

export const list = [
    {
        id:0,
        name: "Jika barang mengalami kerusakan wajar akibat pemakaian (pudar, lecet, stiker terlepas/hilang dan kotor) tidak dikenakan "
    },
    {
        id:1,
        name: "Jika barang mengalami kerusakan ringan (masih bisa diperbaiki, cacat ringan) atau hilang (satu atau beberapa bagian) maka pihak penyewa membayar ganti rugi maksimal 2 kali dari tarif sewa perbulan barang yang dipinjam.",
    },
    {
        id:2,
        name: "Jika barang mengalami kerusakan parah (pecah, patah, sobek, tidak berfungsi atau terkena noda/bau permanen) maka pihak penyewa wajib membayar maksimal 8 kali dari tarif sewa perbulan barang yang dipinjam atau mengganti dengan barang yang ",
    },
    {
        id:3,
        name: "Jika barang mengalami kerusakan hingga tidak bisa disewakan lagi atau hilang sama sekali maka pihak penyewa wajib membayar maksimal 12 kali dari tarif sewa perbulan barang yang dipinjam atau mengganti dengan barang yang sama.",
    },
    {
        id:4,
        name: "Pihak Rentatoy berhak menentukan jenis dan seberapa besar kerusakan yang terjadi."
    }
]

export const data =[
    {   
        uid:0,
        biaya:"8.000 PP",
        daerah: "Pasteur, gunung batu, wastu, sukajadi, cipaku, cipaganti, ciumbuleuit, setiabudi (batas ledeng), wastu."
    },
    {   
        uid:1,
        biaya:"20.000 PP",
        daerah: "Alamanda, singgasana, pasko, cikawao, lengkong, sumber sari, moch toha, cimahi, kosambi, kurdi, kembar, suryalaya, jalan jakarta, ancol, balubur, kosambi, siliwangi, dago (batas hotel sheraton), kebon jati, cibadak, riau, aceh, gatsu (batas TSM), ahmad yani, pajajaran, pramuka, soekarno hatta (batas toha), surapati"
    },
    {
        uid:2,
        biaya:"30.000 PP",
        daerah : "Cibaduyut, kopo, antapani, soekarno hatta (batas batununggal), kebon kopi, cijerah, melong, pharmindo, cikutra, bojong koneng, bumi ligar, cigadung, kircon, puri dago, sariwangi, ciwaruga, pasirluyu, gatsu (setelah TSM), kembar"
    },
    {   
        uid:3,
        biaya:"34.000 PP",
        daerah:"Dago resort, batununggal, kopo (setelah tol), buah batu (sebelum tol)"
    },
    {
        uid:4,
        biaya:"40.000 PP",
        daerah:"Kota baru, makro, margahayu raya, TKI, ciwastra, leuwigajah, margacinta, riung bandung, ujung berung, gede b age, sindanglaya, buah batu (setelah tol)"
    },
    {
        uid:5,
        biaya:"Tidak Kirim",
        daerah: "Cileunyi, cimahi cibeber, parongpong, komplek polri, cilengkrang, gede bage+"
    }
]

export const columns = [
    {
        title:"Biaya",
        key:"biaya",
        dataIndex:"biaya",
        width:"20%"
    },
    {
        title:"Daerah Pengiriman",
        key:"daerah",
        dataIndex:"daerah",
        widt:"80%"
    }
]