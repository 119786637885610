import React, { useEffect } from "react";
import { Row, Breadcrumb } from "antd";
import Navbar from "../components/NavigationBar_Com";
import Footer from "../components/Footer_Com";
import { KategoriAll } from "../components/Kategori_Com";
import { dataKategori } from "../data/DataKategori_Data";
import { Link } from "react-router-dom";
import { useWindowDimensions } from "../logic/Size_Logic";

export default function Kategori_Page() {
  const { width } = useWindowDimensions();
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("search");
    localStorage.removeItem("filter");
  }, []);
  return (
    <div className="container">
      <Navbar />
      <div className="beranda kategori">
        <div className="site-page-kategori">
          {width > 992 ? (
            <div className="breadcrumb">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link to="/">Beranda</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/Semua-Kategori">Kategori</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          ) : (
            ""
          )}
          <div className="site-page-kategori-inner">
            <Row>
              <h1 className="sub-title">
                <strong>KATEGORI</strong>
              </h1>
            </Row>
            <KategoriAll data={dataKategori} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
