import React, { useState, useEffect } from "react";
import { Breadcrumb, Row, Col, Card, Modal, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import { FilterOutlined } from '@ant-design/icons';
import { useWindowDimensions } from "../logic/Size_Logic";
import Nav from "../components/NavigationBar_Com";
import Footer from "../components/Footer_Com";
import AllProduk from "../components/AllProduk_Com";
import Filter from "../components/Filter_Com";
import { getCategories, getProductsByCategories } from "../logic/APIHandler";

export default function ProdukPage(props) {
  const { width } = useWindowDimensions();
  const [isShow, setIsShow] = useState(false);
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [bestseller, setBestseller] = useState([]);
  const [peralatan, setPeralatan] = useState([]);
  const [permainan, setPermainan] = useState([]);
  const [usia, setUsia] = useState([]);
  const [barang, setBarang] = useState([]);
  const [lain, setLain] = useState([]);
  const [checkedPeralatan, setCheckedPeralatan] = useState(false);
  const [checkedPermainan, setCheckedPermainan] = useState(false);
  const [defaultSearch, setDefaultSearch] = useState(localStorage.getItem('search'));
  const [hasLoad, setHasLoad] = useState(false);

  // Method that run first when the page is opened
  useEffect(() => {
    window.scrollTo(0, 0);
    if ( history.location.search ) {
      localStorage.setItem("filter", history.location.search);
      history.replace({ pathname: "/Semua-Produk/"})
    }
    getListOfCategories();
  }, []);

  // Get products from API
  const getProducts = async (categs) => {
    let url = "";

    // If user use search bar to search products
    if ( localStorage.getItem("search") ) {
      url += "?search=" + localStorage.getItem("search");
      // localStorage.removeItem("search");
    }
    // If user use filter to search products
    else {
      if (categs.length > 0) {
        url += "?";
        for (let i = 0; i < categs.length; i++) {
          if (i === categs.length - 1) {
            url += "categories=" + categs[i];
          } else {
            url += "categories=" + categs[i] + "&";
          }
        }
      }
    }

    if (hasLoad) {
      if (url === "") history.push("Semua-Produk");
      else history.push(url);
    }

    let resp = await getProductsByCategories(url);
    if (resp[0] && resp[0].status === 200) {
      const result = resp[0].data;
      setProducts(result);
    }
    else if ( resp[0] && resp[0].status === 204 ) {
      setProducts([]);
    }
  };

  // Get categories data from API
  const getListOfCategories = async (data) => {
    if (!hasLoad) {
      setHasLoad(true);
      
      let arrayCateg = [];
      let defaultCateg = "";

      if ( localStorage.getItem("filter") ) {
        const params = new URLSearchParams(localStorage.getItem("filter"));
        arrayCateg = params.getAll('categories');
        arrayCateg.forEach(categ => {
          defaultCateg += (categ + " ");
        });
      }
      
      let resp = await getCategories();
      if (resp[0] && resp[0].status === 200) {
        let categTemp = [];
        const categories = resp[0].data;
        if (defaultCateg.includes("cat2")) {
          setCheckedPeralatan(true);
        }
        categories.forEach((category) => {
          if (category.category === "Peralatan") {
            let temp_peralatan = [];
            category.sub_category.forEach((subCategory) => {
              if (defaultCateg.includes("cat2") || defaultCateg.includes(subCategory.id)) {
                categTemp.push(subCategory.id);
              }
              temp_peralatan.push({
                id: subCategory.id,
                isChecked: (defaultCateg.includes("cat2") || defaultCateg.includes(subCategory.id)? true
                  : false),
                type: "peralatan",
                value: subCategory.sub,
              });
            });
            setPeralatan(temp_peralatan);
          } else if (category.category === "Permainan") {
            let temp_permainan = [];
            if (defaultCateg.includes("cat3")) {
              setCheckedPermainan(true);
            }
            category.sub_category.forEach((subCategory) => {
              if (subCategory.sub != "Musical Mobile & Hanging Toys") {
                if (defaultCateg.includes("cat2") || defaultCateg.includes(subCategory.id)) {
                  categTemp.push(subCategory.id);
                }
                temp_permainan.push({
                  id: subCategory.id,
                  isChecked: (defaultCateg.includes("cat3") || defaultCateg.includes(subCategory.id)? true
                    : false),
                  type: "permainan",
                  value: subCategory.sub,
                });
              }
            });
            setPermainan(temp_permainan);
          } else if (category.category === "Usia") {
            let temp_usia = [];
            category.sub_category.forEach((subCategory) => {
              if ( defaultCateg.includes(subCategory.id) ) {
                categTemp.push(subCategory.id);
              }
              temp_usia.push({
                id: subCategory.id,
                isChecked: (defaultCateg.includes(subCategory.id)? true : false),
                type: "usia",
                value: subCategory.sub,
              });
            });
            setUsia(temp_usia);
          } else if (category.category === "Ketersediaan Barang") {
            let temp_barang = [];
            let updated = "";
            category.sub_category.forEach((subCategory) => {
              if ( defaultCateg.includes(subCategory.id) ) {
                if ( !updated.includes(subCategory.id) ) {
                  categTemp.push(subCategory.id);
                  updated += (subCategory.id + " ");
                }
              }
              temp_barang.push({
                id: subCategory.id,
                isChecked: (defaultCateg.includes(subCategory.id)? true : false),
                type: "barang",
                value: subCategory.sub,
              });
            });
            setBarang(temp_barang);
          } else if (category.category === "Outdoor & Indoor") {
            let temp_lain = [];
            let updated = "";
            category.sub_category.forEach((subCategory) => {
              if ( defaultCateg.includes(subCategory.id) ) {
                if ( !updated.includes(subCategory.id) ) {
                  categTemp.push(subCategory.id);
                  updated += (subCategory.id + " ");
                }
              }
              temp_lain.push({
                id: subCategory.id,
                isChecked: (defaultCateg.includes(subCategory.id)? true : false),
                type: "lain",
                value: subCategory.sub,
              });
            });
            setLain(temp_lain);
          } else if (category.category === "Bestseller") {
            let temp_bestseller = [];
            let updated = "";
            
            category.sub_category.forEach((subCategory) => {
              if ( defaultCateg.includes(category.id) ) {
                if ( !updated.includes(category.id) ) {
                  categTemp.push(category.id);
                  updated += (category.id + " ");
                }
              }
              temp_bestseller.push({
                id: category.id,
                isChecked: (defaultCateg.includes(category.id)? true : false),
                type: "bestseller",
                value: category.category,
              });
            });
            setBestseller(temp_bestseller);
          }
        });
        
        setCategories(categTemp);
        getProducts(categTemp);
      }
    }
    setTimeout(() => {
      setHasLoad(false);
    }, 1000);
  };

  // Handle when 'Peralatan' checkbox is clicked
  const handleAllCheckedPeralatan = (event) => {
    localStorage.removeItem("search");
    
    let temp = categories;
    let temp_peralatan = peralatan;
    let main = false;
    
    temp_peralatan.forEach((ext) => {
      const selectedCategory = ext.id;
      let filterData = localStorage.getItem("filter")? localStorage.getItem("filter") : false;
      if (event.target.checked) {
        // Jika "Peralatan" sudah dicek, maka cek sub kategori saja
        if (main) {
          if ( !filterData.includes(selectedCategory) ) {
            localStorage.setItem("filter", (filterData + "categories=" + selectedCategory + "&"));
          }
        }
        // Jika "Peralatan" belum dicek, maka cek peralatan & sub katerigorinya
        else {
          main = true;
          if ( filterData ) {
            localStorage.setItem("filter", (filterData + "categories=cat2&categories="
              + selectedCategory + "&"));            
          }
          else {
            localStorage.setItem("filter", ("?categories=cat2&categories="
              + selectedCategory + "&"));
          }
        }

        if (!categories.includes(selectedCategory)) {
          temp.push(selectedCategory);
        }
      }
      else {
        if ( filterData.includes(selectedCategory) ) {
          if ( !main ) {
            main = true;
            filterData = filterData.replace("categories=cat2&", "");
            localStorage.setItem("filter", filterData);
          }
          localStorage.setItem("filter", filterData.replace(("categories=" + selectedCategory + "&"), ""));
        }

        temp = temp.filter((category) => category !== selectedCategory);
      }
      ext.isChecked = event.target.checked;
    });

    setCategories(temp);
    setPeralatan(temp_peralatan);
    setCheckedPeralatan(event.target.checked);
    getProducts(temp);
  };

  // Handle when 'Permainan' checkbox is clicked
  const handleAllCheckedPermainan = (event) => {
    localStorage.removeItem("search");
    let temp = categories;
    let temp_permainan = permainan;
    let main = false;

    temp_permainan.forEach((ext) => {
      const selectedCategory = ext.id;
      let filterData = localStorage.getItem("filter")? localStorage.getItem("filter") : false;
      if (event.target.checked) {
        // Jika "Permainan" sudah dicek, maka cek sub kategori saja
        if (main) {
          if ( !filterData.includes(selectedCategory) ) {
            localStorage.setItem("filter", (filterData + "categories=" + selectedCategory + "&"));
          }
        }
        // Jika "Permainan" belum dicek, maka cek permainan & sub katerigorinya
        else {
          main = true;
          if ( filterData ) {
            localStorage.setItem("filter", (filterData + "categories=cat3&categories="
              + selectedCategory + "&"));            
          }
          else {
            localStorage.setItem("filter", ("?categories=cat3&categories="
              + selectedCategory + "&"));
          }
        }

        if (!categories.includes(selectedCategory)) {
          temp.push(selectedCategory);
        }
      } else {
        if ( filterData.includes(selectedCategory) ) {
          if ( !main ) {
            main = true;
            filterData = filterData.replace("categories=cat3&", "");
            localStorage.setItem("filter", filterData);
          }
          localStorage.setItem("filter", filterData.replace(("categories=" + selectedCategory + "&"), ""));
        }

        temp = temp.filter((category) => category !== selectedCategory);
      }
      ext.isChecked = event.target.checked;
    });

    setCategories(temp);
    setPermainan(temp_permainan);
    setCheckedPermainan(event.target.checked);
    getProducts(temp);
  };

  // Handle when checkbox child is clicked
  const handleCheckChildElement = (event) => {
    // Remove search data
    localStorage.removeItem("search");
    
    const selectedCategory = event.target.id;
    
    // Jika ada filter, maka masukkan filter baru ke local storage
    const filterData = localStorage.getItem("filter")? localStorage.getItem("filter") : false;
    if ( filterData ) {
      // Jika user uncheck filter
      if ( filterData.includes(selectedCategory) ) {
        let res = filterData.replace(("categories=" + selectedCategory + "&"), "");
        // Jika peralatan ter-check, maka uncheck
        if ( filterData.includes("cat2") ) {
          res = res.replace("categories=cat2&", "");
        }
        else if ( filterData.includes("cat3") ) {
          res = res.replace("categories=cat3&", "");
        }
        localStorage.setItem("filter", res);
      }
      // Jika user check filter
      else {
        const currFilter = filterData + "categories=" + selectedCategory + "&";
        localStorage.setItem("filter", currFilter)
      }
    }
    // Jika initial, maka buat local storage awal
    else {
      localStorage.setItem("filter", ("?categories=" + selectedCategory + "&"))
    }
    
    if ( props.match.params.page != 1 ) {
      history.replace({ pathname: "/Semua-Produk/1/" });
      history.go(0);
    }

    let temp_peralatan = peralatan;
    temp_peralatan.forEach((ext) => {
      if ( selectedCategory == "pr7" ) {
        if (ext.id === selectedCategory) ext.isChecked = event.target.checked;
        if (!event.target.checked) setCheckedPeralatan(false);
      }
      else {
        if (ext.id === selectedCategory) ext.isChecked = event.target.checked;
        if (!event.target.checked) setCheckedPeralatan(false);
      }
    });
    setPeralatan(temp_peralatan);

    let temp_permainan = permainan;
    temp_permainan.forEach((ext) => {
      if ( selectedCategory == "pm8" ) {
        if (ext.id === selectedCategory) ext.isChecked = event.target.checked;
        if (!event.target.checked) setCheckedPermainan(false);
      }
      else {
        if (ext.id === selectedCategory) ext.isChecked = event.target.checked;
        if (!event.target.checked) setCheckedPermainan(false);
      }
    });
    setPermainan(temp_permainan);

    let temp_bestseller = bestseller;
    temp_bestseller.forEach((ext) => {
      if (ext.id === selectedCategory) ext.isChecked = event.target.checked;
    });
    setBestseller(temp_bestseller);

    let temp_usia = usia;
    temp_usia.forEach((ext) => {
      if (ext.id === selectedCategory) ext.isChecked = event.target.checked;
    });
    setUsia(temp_usia);

    let temp_barang = barang;
    temp_barang.forEach((ext) => {
      if (ext.id === selectedCategory) ext.isChecked = event.target.checked;
    });
    setBarang(temp_barang);

    let temp_lain = lain;
    temp_lain.forEach((ext) => {
      if (ext.id === selectedCategory) ext.isChecked = event.target.checked;
    });
    setLain(temp_lain);

    // Insert/remove selected category
    let categTemp = categories;
    if (!categories.includes(selectedCategory)) {
      categTemp.push(selectedCategory);
      getProducts(categTemp);
    } else {
      categTemp = categories.filter(
        (category) => category !== selectedCategory
      );
      getProducts(categTemp);
    }

    setCategories(categTemp);
  };

  const toggleShow = () => {
    setIsShow(!isShow);
  };

  return (
    <div className="semua-produk container">
      <Nav defaultValue={defaultSearch} />
      <div className="beranda">
        {width > 992 ? (
          <div className="breadcrumb">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Beranda</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/Semua-Produk">Semua Produk</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        ) : (
          ""
        )}
        <div className="site-page-produk-all">
          {width > 992 ? (
            <Row justify="space-between">
              <Col lg={{ span: 8 }} md={{ span: 7 }}>
                <Card
                  className="card-filter"
                  title="Filter"
                  headStyle={{
                    background: "#F16663",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  style={{
                    border: "none",
                    boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.25)",
                    borderRadius: 10,
                  }}
                >
                  <Filter
                    loading={hasLoad}
                    bestseller={bestseller}
                    peralatan={peralatan}
                    permainan={permainan}
                    usia={usia}
                    barang={barang}
                    lain={lain}
                    checkedPeralatan={checkedPeralatan}
                    checkedPermainan={checkedPermainan}
                    handlePeralatan={handleAllCheckedPeralatan}
                    handlePermainan={handleAllCheckedPermainan}
                    handleChildElement={handleCheckChildElement}
                  />
                </Card>
              </Col>
              <Col lg={{ span: 15 }} md={{ span: 16 }}>
                <AllProduk
                  dataSource={products}
                  loading={hasLoad}
                  currPage={props.match.params.page? props.match.params.page : 1}
                />
              </Col>
            </Row>
          ) : (
            <>
              <Modal
                onOk={toggleShow}
                onCancel={toggleShow}
                visible={isShow}
                style={{
                  bottom: 0,
                  marginLeft: "auto",
                  marginEight: "auto",
                  padding: 0,
                  width: "100%",
                  maxWidth: "100%",
                }}
                footer={false}
              >
                <Filter
                  bestseller={bestseller}
                  peralatan={peralatan}
                  permainan={permainan}
                  usia={usia}
                  barang={barang}
                  lain={lain}
                  checkedPeralatan={checkedPeralatan}
                  checkedPermainan={checkedPermainan}
                  handlePeralatan={handleAllCheckedPeralatan}
                  handlePermainan={handleAllCheckedPermainan}
                  handleChildElement={handleCheckChildElement}
                  onClickApply={toggleShow}
                />
              </Modal>
              <Row>
                <Button
                  className="btn-filter"
                  type="primary"
                  icon={<FilterOutlined />}
                  onClick={toggleShow}
                >
                  Filter
                </Button>
              </Row>
              <br />
              <Row>
                <AllProduk
                  dataSource={products}
                  loading={hasLoad} 
                  currPage={props.match.params.page? props.match.params.page : 1}
                />
              </Row>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
