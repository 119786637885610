import React, { useEffect } from "react";
import Nav from "../components/NavigationBar_Com";
import Footer from "../components/Footer_Com";
import SyaratKetentuan from "../components/SyaratKetentuan_Com";
import CaraMenyewa from "../components/CaraMenyewa_Com";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import "../styles/CaraKetentuan_Styles.css";
import { useWindowDimensions } from "../logic/Size_Logic";

export default function CaraKetentuanPage() {
  const { width } = useWindowDimensions();
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("search");
    localStorage.removeItem("filter");
  }, []);
  return (
    <div className="container">
      <Nav />
      <div className="beranda">
        {width > 992 ? (
          <div className="breadcrumb">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Beranda</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/Cara-Ketentuan">Cara dan Ketentuan</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        ) : (
          ""
        )}
        <div className="site-page-ketentuan">
          <h3>Cara Menyewa</h3>
          <CaraMenyewa />
          <h3>Syarat dan Ketentuan Menyewa</h3>
          <SyaratKetentuan />
          <h3>
            Dengan ini Pihak Penyewa telah mengetahui dan menyetujui ketentuan
            sewa yang berlaku. Terimakasih.
          </h3>
        </div>
      </div>
      <Footer />
    </div>
  );
}
