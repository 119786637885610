import React, { Component } from "react";
import { Row, Col } from "antd";
import "../styles/Colour_Styles.css";
import "../styles/Kategori_Styles.css";
import { Link } from "react-router-dom";

export default class KategoriHome extends Component {
  render() {
    return <>{this.generateCardKategoriHome(this.props.data)}</>;
  }
  generateCardKategoriHome = (data) => {
    var kategoriRow = [];
    var kategoriList = [];
    for (var i = 0; i < data.length; i = i + 1) {
      if (i > 0 && i % 3 === 2) {
        kategoriRow.push(
          <Col span={6} key={data[i].id}>
            <Link to={data[i].path}>
              <CardKategori category={data[i]} key={data[i].id} />
            </Link>
          </Col>
        );
        kategoriList.push(
          <Row
            key={data[i].id}
            className="kategori-row"
            justify="space-between"
          >
            {kategoriRow}
          </Row>
        );
        kategoriRow = [];
      } else {
        kategoriRow.push(
          <Col span={6} key={data[i].id}>
            <Link to={data[i].path}>
              <CardKategori category={data[i]} key={data[i].id} />
            </Link>
          </Col>
        );
      }
    }
    return kategoriList;
  };
}

export class KategoriAll extends Component {
  render() {
    return <>{this.generateCardKategori(this.props.data)}</>;
  }
  generateCardKategori = (data) => {
    var kategoriRow = [];
    var kategoriList = [];
    for (var i = 0; i < data.length; i = i + 1) {
      if (i === data.length - 1) {
        kategoriRow.push(
          <Col lg={{ span: 6, offset: 3 }} key={data[i].id}>
            <Link to={data[i].path}>
              <CardKategori category={data[i]} key={data[i].id} />
            </Link>
          </Col>
        );
        kategoriList.push(
          <Row key={data[i].id} className="kategori-row">
            {kategoriRow}
          </Row>
        );
        kategoriRow = [];
      } else if (i > 0 && i % 3 === 2) {
        kategoriRow.push(
          <Col span={6} key={data[i].id}>
            <Link to={data[i].path}>
              <CardKategori category={data[i]} key={data[i].id} />
            </Link>
          </Col>
        );
        kategoriList.push(
          <Row
            key={data[i].id}
            className="kategori-row"
            justify="space-between"
          >
            {kategoriRow}
          </Row>
        );
        kategoriRow = [];
      }
      else {
        kategoriRow.push(
          <Col span={6} key={data[i].id}>
            <Link to={data[i].path}>
              <CardKategori category={data[i]} key={data[i].id} />
            </Link>
          </Col>
        );
      }
    }
    return kategoriList;
  };
}
const CardKategori = (props) => {
  return (
    <>
      <div className="kategori-card">
          <img src={props.category.image} alt={props.category.name} className={props.category.className}/>
      </div>
    </>
  );
};
