import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { Row, Col } from "antd";
import "../styles/Colour_Styles.css";
import "../styles/Footer_Styles.css";

export default function FooterCom() {
  return (
    <Footer
      className="site-page-footer"
      style={{
        background: "#F16663",
        color: "white",
        bottom: 0,
        width: "100%",
      }}
    >
      <div className="site-page-footer-inner">
        <Row justify="space-between" style={{ fontSize: 14  }}>
          <Col>
            <h1>RENTATOY</h1>
            <ul className="list-footer">
              <Link to="/Tentang-Kami" className="list-footer-header">Tentang Kami</Link>
              <li className="list-footer-header">Kontak</li>
              <li>+62 822 9891 6868</li>
              <li>rentatoybandung@gmail.com</li>
              <li className="list-footer-header">Media Sosial</li>
            </ul>
            <div className="media-sosial">
              <Link to={{ pathname: "https://www.facebook.com/rentatoy.rentatoy" }} target="_blank">
                <img
                  src={
                    process.env.PUBLIC_URL + "/Rentatoy-Aset/Facebook-Aset.svg"
                  }
                  alt="facebook"
                />
              </Link>
              <Link to={{ pathname: "https://www.instagram.com/rentatoy_bdg/" }} target="_blank">
                <img
                  src={
                    process.env.PUBLIC_URL + "/Rentatoy-Aset/Instagram-Aset.svg"
                  }
                  alt="instagram"
                />
              </Link>
              <Link to={{ pathname: "https://api.whatsapp.com/send?phone=6282298916868" }} target="_blank">
                <img
                  src={
                    process.env.PUBLIC_URL + "/Rentatoy-Aset/Whatsapp-Aset.svg"
                  }
                  alt="whatsapp"
                />
              </Link>
            </div>
          </Col>
          <Col>
            <h1>LAYANAN PELANGGAN</h1>
            <ul className="list-footer">
              <Link to="/Cara-Ketentuan" className="list-footer-header">Cara dan Ketentuan</Link>
              <Link to="/Pendaftaran" className="list-footer-header">Pendaftaran Diri</Link>
            </ul>
          </Col>
        </Row>
      </div>
      {/* <p>Halo</p> */}
    </Footer>
  );
}
