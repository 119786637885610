import React, { Component } from "react";
import "../styles/CaraKetentuan_Styles.css";

export default class SyaratKetentuanCom extends Component {
  render() {
    return (
      <>
        <ol>
            <li>Cek barang yang Anda inginkan di katalog website Rentatoy.</li>
            <li>Hubungi Rentatoy melalui Whatsapp untuk pemesanan & ketersediaan barang.</li>
            <li>Anda akan dipandu untuk mengisi data sewa & perjanjian sewa Rentatoy.</li>
            <li>Admin akan menginfokan biaya sewa & biaya kirim yang dibayar di muka.</li>
        </ol>
      </>
    );
  }
}