import React, { useEffect } from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import Nav from "../components/NavigationBar_Com";
import Footer from "../components/Footer_Com";
import "../styles/Colour_Styles.css";
import "../styles/Kategori_Styles.css";
import "../styles/TentangKami_Styles.css";
import "../styles/Default_Styles.css";
import { useWindowDimensions } from "../logic/Size_Logic";

export default function TentangKamiPage() {
  const { width } = useWindowDimensions();
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("search");
    localStorage.removeItem("filter");
  }, []);
  return (
    <div className="container">
      <Nav />
      {width > 992 ? (
        <div className="beranda tentang-kami top">
          <div className="breadcrumb">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Beranda</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/Tentang-Kami">Tentang Kami</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        className="site-page-tentang-kami-img"
        style={{
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0) 51.04%, #FFFFFF 100%), url('Rentatoy-Aset/TentangKami-Aset.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
      />
      <div className="beranda tentang-kami">
        <div className="site-page-tentang-kami">
          <h2 style={{ color: "#CE3336" }}>
            <strong>Tentang Kami</strong>
          </h2>
          <h3>MASALAH YANG DIHADAPI IBU-IBU JAMAN ZEKARANG:</h3>
          <ol>
            <li>Beli walker hanya terpakai 2 -3 bulan</li>
            <li>Beli high-chair, anaknya ga suka duduk di highchair</li>
            <li>Beli stroller, jarang dipakai.</li>
            <li>Beli playmatt, hanya terpakai sebentar.</li>
            <li>Beli mainan, seminggu bosan</li>
          </ol>
          <h3>
            Rentatoy didirikan dengan harapan membahagiakan anak-anak melalui
            jasa penyewaan mainan berkualitas dan mendidik tanpa harus
            mengeluarkan uang banyak. Rentatoy berharap dapat memenuhi kebutuhan
            stimulasi anak balita dan membantu mengembangkan potensi anak Anda
            secara optimal. Rentatoy menyediakan mainan & baby-gear yang sesuai
            dengan usia dan perkembangan anak Anda.
          </h3>
          <h3>Manfaat menyewa mainan dibanding membeli:</h3>
          <ol>
            <li>
              Mainan berkualitas dan aman cenderung mahal harganya, melalui
              rental Anda dapat memainkan mainan edukatif tsb.
            </li>
            <li>
              Mainan hanya digunakan dalam jangka waktu singkat karena anak-anak
              dibawah usia 6 tahun cepat bosan dan berkembang dengan pesat.
            </li>
            <li>
              Dapat mencoba berbagai mainan edukatif dan stimulatif yang beragam
              sesuai dengan waktu yang dikehendaki.
            </li>
            <li>
              Menjadi bahan percobaan gear sebelum membeli apakah cocok atau
              tidak.
            </li>
          </ol>
          <h3>
            Setelah membaca manfaatnya, lebih baik menyewa dibandingkan membeli
            kan moms. Ayo merental sekarang juga. Caranya mudah saja, Anda
            tinggal memilih mainan yang ingin disewa, Rentatoy akan
            mengantarkannya ke rumah Anda dan mengambilnya kembali setelah Anda
            selesai menyewa.
          </h3>
        </div>
      </div>
      <Footer />
    </div>
  );
}
