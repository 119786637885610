import React, { useEffect } from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { FormCom } from "../components/Form_Com";
import Nav from "../components/NavigationBar_Com";
import Footer from "../components/Footer_Com";
import { useWindowDimensions } from "../logic/Size_Logic";

export default function Pendaftara_Page() {
  const { width } = useWindowDimensions();
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("search");
    localStorage.removeItem("filter");
  }, []);
  return (
    <div div className="container">
      <Nav />
      <div className="beranda">
        {width > 992 ? (
          <div className="breadcrumb">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/">Beranda</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/Pendaftaran">Pendaftaran</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        ) : (
          ""
        )}
        <div className="site-page-pendaftaran">
          <p
            style={{
              color: "#CE3336",
              fontWeight: "bold",
              paddingBottom: "20px",
            }}
          >
            Pendaftaran Diri
          </p>
          <FormCom />
        </div>
      </div>
      <Footer />
    </div>
  );
}
