import React, { Component } from "react";
import {
  caraKetentuan,
  list,
  data,
  columns,
} from "../data/DataCaraKetentuan_Data";
import { Table } from "antd";
import "../styles/CaraKetentuan_Styles.css";

export default class SyaratKetentuanCom extends Component {
  render() {
    return (
      <>
        <ol>
          {caraKetentuan.map((item, index) => {
            if (index === 10) {
              return (
                <li key={index}>
                  {item.text}
                  <ol type="a" style={{listStyle:"lower-alpha"}}>
                    {list.map((item) => (
                      <li key={item.id}>{item.name}</li>
                    ))}
                  </ol>
                </li>
              );
            } else if (index === 12) {
              return (
                <li key={index}>
                  {item.text}
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    bordered
                    rowKey={(record) => record.uid}
                  />
                  Bagi yang ingin menghindari biaya pengiriman, mainan dapat
                  diambil di area Setra Sari dengan perjanjian terlebih dahulu.
                </li>
              );
            } else {
              return <li key={index}>{item.text}</li>;
            }
          })}
        </ol>
      </>
    );
  }
}
